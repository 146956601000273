$primary: #c53d3d;
$secondary: #9e3838;
$info: #fed7d7;

@import "node_modules/bootstrap/scss/bootstrap";
// @import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// @font-face {
//   font-family: 'Geist';
//   src: url('./fonts/GeistVF.woff') format('woff'),
//     url('./fonts/GeistVF.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

body {
  // font-family: 'Geist', sans-serif;
  font-family: "Inter";
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.status-badge {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  /* Default padding for desktop */
  font-size: 0.875rem;
  /* Default font size for desktop */
  border-radius: 5px;
  white-space: nowrap;
  /* Prevent text wrapping */
  text-align: center;
}

@media (max-width: 576px) {
  .status-badge {
    padding: 0.25rem 0.5rem;
    /* Smaller padding for mobile */
    font-size: 0.75rem;
    /* Smaller font size for mobile */
  }
}

.title-container {
  display: flex;
  flex-wrap: wrap;
  /* Allows wrapping for responsive behavior */
  gap: 0.5rem;
  /* Add spacing between elements */
  justify-content: space-between;
  /* Ensures good alignment with neighboring elements */
  align-items: center;
  /* Vertical alignment for all elements */
}

.title-text {
  font-size: 1.25rem;
  /* Default font size */
  font-weight: bold;
  /* Matches inter-700 */
  white-space: nowrap;
  /* Prevent text wrapping initially */
  overflow: hidden;
  /* Clip overflowing text */
  text-overflow: ellipsis;
  /* Add ellipsis for overflow */
  max-width: 100%;
  /* Ensures it stays within its container */
}

@media (max-width: 576px) {
  .title-text {
    font-size: 1rem;
    /* Adjust font size for smaller screens */
    white-space: normal;
    /* Allow wrapping on smaller screens */
    text-align: left;
    /* Align text to the left for better readability */
  }

  .title-container {
    flex-direction: column;
    /* Stack elements vertically */
    align-items: flex-start;
    /* Align items to the start */
  }
}

.responsive-text {
  white-space: nowrap;
  /* Prevents wrapping */
  overflow: hidden;
  /* Hides overflow text */
  text-overflow: ellipsis;
  /* Adds "..." for overflow text */
  max-width: 100%;
  /* Ensures it adapts to container width */
}

@media (max-width: 576px) {

  /* Small screen adjustments */
  .responsive-text {
    font-size: 12px;
    /* Smaller font size for mobile */
    padding: 0.2rem 0.4rem;
    /* Adjust padding for tags */
  }
}

.responsive-container {
  display: flex;
  flex-wrap: wrap;
  /* Allows wrapping in small screens */
  align-items: center;
  gap: 0.5rem;
  /* Adds spacing between items */
}

@media (max-width: 576px) {
  .row .col-12 {
    width: 100%;
    /* Full width for mobile */

    margin-bottom: 0.5rem;
    /* Add spacing between filters */
  }

  .custom-select-wrapper {
    width: 100%;
    /* Full width dropdown */
  }

  .custom-select {
    width: 100%;
  }

  .custom-close {
    position: absolute;

    right: 10px;
  }

  .custom-dropdown-icon {
    position: absolute;

    right: 10px;
  }
}

// -----scrollbar-none
.scrollbar-none::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.overflowX-scroll {
  overflow-x: "scroll" !important;
}

.overflowY-scroll {
  overflow-y: "scroll" !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-grow {
  flex-grow: 1 !important;
  flex: 1 0 auto !important;
}

.wrapper {
  // width: 100%;
  padding: 0 10px;
  margin-right: auto;
  margin-left: auto;
}

.add-product-modal .btn-close {
  color: #0e0e0f !important;
}

.strike-out-text {
  text-decoration: line-through;
}

// @media only screen and (max-width: 600px) {
//   .wrapper {
//     // max-width: 584px;
//     padding: 0 10px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }

// @media only screen and (min-width: 600px) {
//   .wrapper {
//     // max-width: 572px;
//     padding: 0 20px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }

// @media only screen and (min-width: 768px) {
//   .wrapper {
//     // max-width: 752px;
//     padding: 0 20px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }

// @media only screen and (min-width: 992px) {
//   .wrapper {
//     // max-width: 964px;
//     padding: 0 20px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }

// @media only screen and (min-width: 1200px) {
//   .wrapper {
//     // max-width: 1160px;
//     padding: 0 20px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }

// @media (min-width: 1400px) {
//   .wrapper {
//     max-width: 1320px !important;
//   }
// }

@media (min-width: 1400px) {
  .container {
    max-width: 1380px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1520px !important;
  }
}

// @media only screen and (max-width: 600px) {...}
// @media only screen and (min-width: 600px) {...}
// @media only screen and (min-width: 768px) {...}
// @media only screen and (min-width: 992px) {...}
// @media only screen and (min-width: 1200px) {...}

// external css manipulation
/* .form-control {
  border: 1px solid black;
} */

.form-select:focus,
.focus-none:focus {
  // border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
  // border-color: #d95656;
}

.input-group:focus,
.form-control:focus {
  box-shadow: none;
  border: 1px solid black;
}

.slick-track {
  margin-left: 0;
}

.nav-link {
  color: #1a1c1f !important;
  font-weight: 500;
}

.nav-link.show {
  color: #d95656 !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #d95656 !important;
  cursor: pointer;
}

.nav-tabs {
  border-bottom: 2px solid lightgray;
  font-weight: 600;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid $secondary;
  color: $secondary !important;
}

//
body {
  // font-family: 'Geist', sans-serif !important;
  font-family: "Inter" !important;
}

.bg-primary {
  background-color: #d95656 !important;
  color: white !important;
}

.bg-brown {
  background-color: #a53030 !important;
}

.bg-gray {
  background: #eaecf0;
  color: #475467;
  border-color: #eaecf0;
  font-weight: 600;
}

.bg-gray:hover {
  background-color: #d7d8db;
  border-color: #d7d8db;
}

.btn-bg-custom:hover {
  background-color: #f1f3f4 !important;
}

.bg-gray-50 {
  background: #f1f3f4;
}

.border-gray-25 {
  border-color: #f8f9fa !important;
}

.border-brand-500 {
  border-color: #c53d3d !important;
}

.border-gray-100 {
  border-color: #e8eaed !important;
}

.border-gray-200 {
  border-color: #dadce0 !important;
}

.border-gray-300 {
  border-color: #bdc1c6 !important;
}

.border-gray-400 {
  border-color: #9aa0a6 !important;
}

.border-gray-600 {
  border-color: #5f6368 !important;
}

.border-success-300 {
  border-color: #2fd181 !important;
}

.border-warning-300 {
  border-color: #ffd84e !important;
}

.border-error-300 {
  border-color: #ff7a72 !important;
}

.border-dashed {
  border-style: dashed !important;
}

.btn {
  font-weight: 600;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid $primary;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

// GEIST
.inter {
  // font-family: "Geist", sans-serif !important;
  font-family: "Inter" !important;
}

.inter-400 {
  // font-family: "Geist", sans-serif !important;
  font-family: "Inter" !important;
  font-weight: 400;
  font-style: normal;
}

// .inter-400-i {
//   font-family: "Geist", sans-serif !important;
//   font-family: "Inter" !important;
//   font-weight: 400;
//   font-style: italic;
// }

.inter-500 {
  // font-family: "Geist", sans-serif !important;
  font-family: "Inter" !important;
  font-weight: 500;
  font-style: normal;
}

.inter-600 {
  // font-family: "Geist", sans-serif !important;
  font-family: "Inter" !important;
  font-weight: 600;
  font-style: normal;
}

.inter-700 {
  // font-family: "Geist", sans-serif !important;
  font-family: "Inter" !important;
  font-weight: 700;
  font-style: normal;
}

// focus outline
.outline-none:focus,
.outline-none:active {
  outline: none !important;
  box-shadow: none !important;
}

// focus outline
.outline-text-none:focus,
.outline-text-none:active {
  outline: none !important;
  box-shadow: 4px 4px 10px #111, -5px -5px 10px #555;
}

.rotate-on-hover {
  animation: rotateY 0.8s ease-in-out 1;
}

.rotate-on-hover-negative {
  animation: rotateY-negative 0.8s ease-in-out 1s;
}

.text-primary {
  color: #a53d3d !important;
  letter-spacing: -0.03em;
}

.text-primary-500 {
  color: #c53d3d !important;
  letter-spacing: -0.03em;
}

.brand-primary-500 {
  background-color: #c53d3d !important;
  border-color: #c53d3d !important;
}

.text-white {
  color: #ffffff !important;
  letter-spacing: -0.03em;
}

.text-gray-900 {
  color: #1a1c1f !important;
  letter-spacing: -0.03em;
}

.text-gray-800 {
  color: #2e3134 !important;
  letter-spacing: -0.03em;
}

.text-gray-700 {
  color: #3c4043 !important;
  letter-spacing: -0.03em;
}

.text-gray-600 {
  color: #5f6368 !important;
  letter-spacing: -0.03em;
}

.text-gray-500 {
  color: #80868b !important;
  letter-spacing: -0.03em;
}

.text-gray-400 {
  color: #9aa0a6 !important;
  letter-spacing: -0.03em;
}

.text-gray-300 {
  color: #bdc1c6 !important;
  letter-spacing: -0.03em;
}

.text-gray-200 {
  color: #dadce0 !important;
  letter-spacing: -0.03em;
}

.text-success-900 {
  color: #02542b !important;
  letter-spacing: -0.03em;
}

.text-warning-900 {
  color: #624d00 !important;
  letter-spacing: -0.03em;
}

.text-error-900 {
  color: #ab0a00 !important;
  letter-spacing: -0.03em;
}

.text-error-500 {
  color: #ff3838 !important;
}

.line-below-heading {
  color: #999999;
}

.hover:hover {
  color: #a53d3d !important;
}

// react-select focus-none
.css-t3ipsp-control {
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
  border-color: transparent !important;
}

// -----no focus
.focus-none:focus,
.focus-none:visited {
  // border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: currentColor;
}

// -----input focus
.form-check-input:focus,
.form-select:focus,
.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

// -----checkbox accent color
.form-check-input:checked,
.form-check-input::after {
  background-color: #3c4043 !important;
  border-color: #3c4043 !important;
  box-shadow: none !important;
  outline: none !important;
  box-sizing: unset !important;
}

input[type="checkbox"] {
  accent-color: #3c4043 !important;
}

.site-input {
  height: 40px;
}

.dropdown-menu {
  max-width: 175px;
  width: auto;
}

.dropdown-menu.show {
  top: 0 !important;
  /* Remove extra space above the dropdown menu */
}

// .scrollbar::-webkit-scrollbar {
//   width: 4px !important;
//   margin: 5px !important;
// }

// .scrollbar::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px #858585 !important;
//   border-radius: 6px !important;
// }

// .scrollbar::-webkit-scrollbar-thumb {
//   background: $grey !important;
//   border-radius: 6px !important;
// }

// .scrollbar::-webkit-scrollbar-thumb:hover {
//   background: #0275aa !important;
// }

// .scrollbar-hor::-webkit-scrollbar {
//   width: 3px !important;
//   margin: 4px !important;
//   height: 3px !important;
// }

// .scrollbar-hor::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px #f2f2f2 !important;
//   border-radius: 4px !important;
// }

// .scrollbar-hor::-webkit-scrollbar-thumb {
//   background: $grey !important;
//   border-radius: 4px !important;
//   height: 3px !important;
// }

// .scrollbar-hor::-webkit-scrollbar-thumb:hover {
//   background: #dddddd !important;
// }

// -----scrollbar-none
.scrollbar-none::-webkit-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #222;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(73, 72, 72);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 72, 72);
}

.noScrollBar::-webkit-scrollbar {
  scrollbar-width: 4px;
  /* for Chrome, Safari, and Opera */
}

.noScrollBar {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

.w-60 {
  width: 60%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.case-study-box {
  width: 100%;
}

.iframe-preview {
  cursor: default;

  &:hover .iframe-hover {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
}

.loginMobileMain {
  position: relative;
  width: 310px;
  height: 110px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  overflow: hidden;
}

.loginMobileFill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.w-dialog {
  max-width: "100%";
}

.w-contentBox {
  border-radius: 40px;
  --smooth-corners: 20;
  mask-image: paint(smooth-corners);
  -webkit-mask-image: paint(smooth-corners);
  background: #0e0e0f;
}

.verify-container {
  height: 100%;
}

.notch-div {
  background-color: #1f1f1f;
  width: 300px;
  border-radius: 10px;
}

.notch {
  width: 30px;
  height: 30px;
  background-color: #1f1f1f;
  rotate: 45deg;
  position: absolute;
  margin-bottom: -10px;
  border-radius: 10px;
}

.counter-ol {
  counter-reset: item;
}

.counter-li {
  display: block;
}

.counter-li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.trash-icon {
  background-color: #2f2f2f !important;
}

.blog-top-section {
  background-color: #27292a;
  border-radius: 20px;
}

.blog-card {
  background-color: #27292a;
  border-radius: 20px;
}

.h-80vh {
  height: 80vh;
}

.h-90vh {
  height: 90vh;
}

.h-100vh {
  height: 100vh;
}

.h-200vh {
  height: 200vh;
}

.h-150vh {
  height: 150vh;
}

.h-125vh {
  height: 125vh;
}

.sitemap-link {
  color: #51c0ff;
}

.bg-faded {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 40px;
  // min-width: 1500px  !important;
}

.modal-width {
  min-width: 90%;
  min-height: 100%;
}

.custom-width-modal {
  max-width: 432px;
  width: 90%;
}

.location-modal-width {
  max-width: 485px;
  /* Set your desired width */
  width: 80%;
  /* Example for responsive width */
}

.archive-modal-width {
  max-width: 388px;
  width: 80%;
  border-radius: 6px;
}

.xyz {
  min-height: 90vh;
}

// NAVBAR ITEMS

.dropdown-menu {
  padding: 0;
  top: 97% !important;
  width: 100%;
  // transform: translate(0px, 70px);
}

.navbar-select {
  border: 0;
  color: #1a1c1f;
  font-weight: 500;
}

.nav-login-btn {
  width: 100%;
  color: #d95656;
  border: 0;
}

.card {
  padding: 10px;
  // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 3px 1px #10182826;
  border-radius: 10px;
  background-color: white;
}

.bg-disabled {
  background: #e9ecef !important;
}

.bg-gray-25 {
  background: #f8f9fa;
}

.bg-gray-50 {
  background: #f1f3f4;
}

.bg-gray-300 {
  background: #bdc1c6;
}

.bg-brand-25 {
  background: #fdf3f3;
}

.bg-brand-50 {
  background: #fbe5e5;
}

.bg-brand-100 {
  background: #f8d0d0;
}

.bg-brand-500 {
  background: #c53d3d;
}

.bg-brand-500-half {
  background: linear-gradient(to top, #c53d3d 70%, #ffffff 50%);
}

.bg-success-50 {
  background: #d7ffeb !important;
}

.bg-warning-50 {
  background: #fff2c4 !important;
}

.bg-error-50 {
  background: #ffdcda !important;
}

// AUTOMATIC SCROLL EFFECTS

.img-card img {
  padding: 0 15px;
  border-radius: 10px;

  // object-fit: cover;
}

.img-card img:last-of-type {
  margin: 0 10px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 10px;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.wrapper {
  position: absolute;
  display: flex;
}

section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

div.item {
  display: inline-block;
  padding: 1em;
}

div.line {
  display: inline-block;
  border-top: 2px solid black;
  width: 3em;
}

// IMAGE SLIDER

@media (min-width: 768px) {
  .filter-container {
    min-width: 150px;
    height: 40px;
  }
}

@media (max-width: 767px) {
  .filter-container {
    height: 40px;
  }
}

@media (min-width: 768px) {
  .filter-container-library {
    min-width: 150px;
    height: 38px;
  }
}

@media (max-width: 767px) {
  .filter-container-library {
    height: 40px;
  }
}

// custom slider css
.custom-prev-arrow,
.custom-next-arrow {
  width: 40px !important;
  height: 40px !important;
  background: #f1f3f4 !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000 !important;
}

.custom-prev-arrow:before,
.custom-next-arrow:before {
  content: "";
}

.custom-prev-arrow:hover,
.custom-next-arrow:hover {
  color: #000;
  background-color: #f1f3f4;
}

/* Custom dark controls */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: $primary;
  width: 6px;
  height: 6px;
  padding: 12px;
  border-radius: 50%;
}

.carousel-control-prev,
.carousel-control-next {
  padding: 10px;
  border-radius: 50%;
}

.carousel-control-prev {
  left: -16px;
  /* Adjust left position to reduce the space */
}

.carousel-control-next {
  right: -16px;
  /* Adjust right position to reduce the space */
}

// space slider
.custom-prev-arrow-spaces,
.custom-next-arrow-spaces {
  width: 32px !important;
  height: 32px !important;
  background: #c53d3d !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.custom-prev-arrow-spaces:before,
.custom-next-arrow-spaces:before {
  content: "";
}

.custom-prev-arrow-spaces:hover,
.custom-next-arrow-spaces:hover {
  color: #000;
  background-color: #f1f3f4;
}

.img-container {
  width: 250px;
  height: 250px;
  position: relative;
  margin: 30px;
}

.img-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.img-overlay {
  z-index: 1;
  // margin: 30px;
}

.card-primary-hover {
  border: 1px solid #e8eaed;
  box-shadow: none !important;
  cursor: pointer;
}

.card-primary-hover:hover {
  border: 1px solid #bdc1c6;
  color: #c53d3d !important;
  cursor: pointer;
}

.social-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $info;
  color: $primary;
  border: none;
}

.social-btn:hover {
  color: white;
}

.social-btn-professional {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: $info;
  color: $primary;
  border: none;
}

.share-like-btn {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  color: #000000;
  border: none;
}

.share-like-btn:hover {
  background-color: $primary;
  color: #ffffff;
}

.share-like-btn-100 {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: #e8eaed;
  color: #000000;
  border: none;
}

.share-like-btn-100:hover {
  background-color: $primary;
  color: #ffffff;
}

.primary-hover:hover {
  background-color: #d95656;
  color: white;
}

.rounded-btn {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.rounded-btn-brand {
  border-radius: 50%;
  width: 30px;
  /* Default width for desktop */
}

@media (max-width: 768px) {

  /* Adjust for mobile devices */
  .rounded-btn-brand {
    width: 40px;
  }
}

.img-absolute-btn {
  background: #eaecf0;
  color: black;
  border-color: #eaecf0;
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 14px 8px 14px;
}

.img-absolute-btn:active {
  background-color: $primary;
  color: white;
}

.accordion-button:not(.collapsed) {
  // background-color: #f9fafb; // #f0f3f9
  background-color: #f0f3f9 !important;
  box-shadow: none;
}

.accordion-button:focus {
  background-color: #f0f3f9 !important;
  box-shadow: none;
}

.accordion-body {
  background-color: #f0f3f9 !important;
}

.scroll-element-container {
  display: flex;
  /* Use Flexbox */
  height: 100%;
  /* Set the height of the container */
}

.scrollable-element {
  max-height: calc(100vh - 245px);
  overflow-y: auto;
}

.scrollable-element::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for WebKit browsers */
}

.stepper {
  .line {
    width: 2px;
    background-color: lightgrey !important;
    border: lightgray;
  }

  .lead {
    font-size: 1.1rem;
  }
}

.signup-card {
  padding: 18px;
  background: #f9fafb;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 1px #10182826;
}

.partner-signup-card {
  background: #f0f3f9;
  border-radius: 10px;
  box-shadow: 2px 2px 3px 1px #10182826;
  width: 410;
}

// Range Slider
$thumb-size: 16px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
}

.range-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: calc(#{$thumb-size} + 1.6rem);
}

.input-wrapper {
  width: calc(100% + #{$thumb-size});
  margin: 0 calc(#{$thumb-size} / -2);
  position: absolute;
  height: $thumb-size;
}

.control-wrapper {
  width: 100%;
  position: absolute;
  height: $thumb-size;
}

.range-input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
}

.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 6px;
  border-radius: 3px;
  background: lightgrey;
}

.inner-rail {
  position: absolute;
  height: 100%;
  background: $primary;
  opacity: 0.5;
}

.control {
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  position: absolute;
  background: $primary;
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Partner Page */
.partner-card-border {
  border-radius: 6px;
  border: 1px solid #e8eaed;
}

/* Custom Accordion */
.custom-accordion {
  width: 100%;
  // min-width: 400px;
  margin: auto;
}

.accordion-item {
  border: 1px solid #e8eaed;
  border-radius: 8px !important;
  margin-bottom: 10px;
  overflow: hidden;
  // transition: opacity 0.3s ease-in-out;
}

.accordion-item.active {
  background: #f1f3f4;
}

.accordion-title {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-title:hover {
  background: #f1f1f1;
}

.active-title {
  color: #d32f2f;
}

/* Add animation to accordion body */
.accordion-body {
  max-height: 0;
  overflow: hidden;
  padding: 0 15px;
  background: #f1f3f4 !important;
  opacity: 0;
  // transition: max-height 0.3s ease, padding 0.3s ease;
  transition: opacity 1.2s ease;
}

/* Override the global accordion-body styles for Bootstrap's Accordion */
.accordion .accordion-body {
  max-height: unset !important;
  overflow: visible !important;
  opacity: 1 !important;
  padding: 15px !important;
  transition: none !important;
}

/* Optional: Make sure the Bootstrap accordion collapse works properly */
.accordion-collapse:not(.show) {
  display: none;
}

.accordion-collapse.show {
  display: block;
}

.body-visible {
  max-height: 250px;
  /* Adjust based on content height */
  padding: 15px;
  opacity: 1;
}

.content {
  margin: 0;
  padding-bottom: 24px;
}

.designerbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}


.designeritem{
  width: 100px;
}
.designeritem > img {
  width: 67px;
  height: 67px;
  border-radius: 50%;
}

/* Small devices (landscape phones, 576px and up) xs*/
@media (max-width: 576px) {
  .slick-dots li {
    margin: 0px !important;
  }

  .custom-dot {
    background: white;
    border: 1px solid #bdc1c6;
    border-radius: 5px;
    width: 9px !important;
    height: 9px !important;
  }

  .slick-dots li.slick-active .custom-dot {
    background: #2e3134;
    border: 1px solid #bdc1c6;
    border-radius: 5px;
    width: 10px !important;
    height: 10px !important;
  }
}

.slick-dots li {
  margin: 0px !important;
}

.custom-dot {
  background: white;
  border: 1px solid #bdc1c6;
  border-radius: 5px;
  width: 9px !important;
  height: 9px !important;
}

.slick-dots li.slick-active .custom-dot {
  background: #2e3134;
  border: 1px solid #bdc1c6;
  border-radius: 5px;
  width: 10px !important;
  height: 10px !important;
}

/* Add these styles to your CSS */
.builder-carousel .carousel-indicators {
  margin-bottom: 0;
}

.builder-carousel .carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #666;
}

.builder-carousel .carousel-indicators .active {
  background-color: #333;
}

.builder-carousel .carousel-item {
  transition: transform 0.6s ease-in-out;
}

@media (max-width: 768px) {
  .btn {
    font-weight: 600;
    font-size: 12px !important;
  }

  h1,
  .h1 {
    font-size: 28px !important;
    letter-spacing: -0.03em;
  }

  h2,
  .h2 {
    font-size: 22px !important;
    letter-spacing: -0.03em;
  }

  h3,
  .h3 {
    font-size: 18px !important;
    letter-spacing: -0.03em;
  }

  h4,
  .h4 {
    font-size: 16px !important;
    letter-spacing: -0.03em;
  }

  .body-title {
    font-size: 14px !important;
    letter-spacing: -0.03em;
  }

  .body-text1 {
    font-size: 12px !important;
    letter-spacing: -0.03em;
  }

  .body-text2 {
    font-size: 12px !important;
    letter-spacing: -0.03em;
  }

  .body-text3 {
    font-size: 10px !important;
    letter-spacing: -0.03em;
  }
}

// /* // Medium devices (tablets, 768px and up) sm*/
@media (min-width: 768px) {

  h1,
  .h1 {
    font-size: 36px !important;
    letter-spacing: -0.03em;
  }

  h2,
  .h2 {
    font-size: 28px !important;
    letter-spacing: -0.03em;
  }

  h3,
  .h3 {
    font-size: 18px !important;
    letter-spacing: -0.03em;
  }

  h4,
  .h4 {
    font-size: 16px !important;
    letter-spacing: -0.03em;
  }

  .body-title {
    font-size: 14px !important;
    letter-spacing: -0.03em;
  }

  .body-text1 {
    font-size: 12px !important;
    letter-spacing: -0.03em;
  }

  .body-text2 {
    font-size: 10px !important;
    letter-spacing: -0.03em;
  }

  .body-text3 {
    font-size: 10px !important;
    letter-spacing: -0.03em;
  }
}

// /* // Large devices (desktops, 992px and up) md*/
@media (min-width: 992px) {

  h1,
  .h1 {
    font-size: 40px !important;
    letter-spacing: -0.03em;
  }

  h2,
  .h2 {
    font-size: 32px !important;
    letter-spacing: -0.03em;
  }

  h3,
  .h3 {
    font-size: 20px !important;
    letter-spacing: -0.03em;
  }

  h4,
  .h4 {
    font-size: 18px !important;
    letter-spacing: -0.03em;
  }

  .body-title {
    font-size: 16px;
    letter-spacing: -0.03em;
  }

  .body-text1 {
    font-size: 14px !important;
    letter-spacing: -0.03em;
  }

  .body-text2 {
    font-size: 12px !important;
    letter-spacing: -0.03em;
  }

  .body-text3 {
    font-size: 10px !important;
    letter-spacing: -0.03em;
  }
}

/* // Extra large devices (large desktops, 1200px and up) lg*/
@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 48px !important;
    letter-spacing: -0.03em;
  }

  h2,
  .h2 {
    font-size: 36px !important;
    letter-spacing: -0.03em;
  }

  h3,
  .h3 {
    font-size: 24px !important;
    letter-spacing: -0.03em;
  }

  h4,
  .h4 {
    font-size: 20px !important;
    letter-spacing: -0.03em;
  }

  .body-title {
    font-size: 18px !important;
    letter-spacing: -0.03em;
  }

  .body-text1 {
    font-size: 16px !important;
    letter-spacing: -0.03em;
  }

  .body-text2 {
    font-size: 14px !important;
    letter-spacing: -0.03em;
  }

  .body-text3 {
    font-size: 12px !important;
    letter-spacing: -0.03em;
  }
}

input[type="range"] {
  accent-color: #3c4043 !important;
}

// space modal
// brand details
.spaces-slider-modal-content .slick-list {
  width: 100% !important;
  height: 100% !important;
}

.spaces-slider-modal-content .slick-track {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;
}

.spaces-slider-modal-content .slick-slide {
  // flex-grow: 1 !important;
  // flex: 1 0 auto !important;
  display: flex !important;
  flex: auto;
  height: unset !important;
  min-height: 100% !important;
}

.spaces-slider-modal-content .slick-slide div {
  width: 100% !important;
  height: 100% !important;
}

// project list
.spaces-slider-modal-content .slick-list {
  width: 100% !important;
  height: 100% !important;
}

.spaces-slider-modal-content .slick-track {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;
}

.spaces-slider-modal-content .slick-slide {
  // flex-grow: 1 !important;
  // flex: 1 0 auto !important;
  display: flex !important;
  flex: auto;
  height: unset !important;
  min-height: 100% !important;
}

.spaces-slider-modal-content .slick-slide div {
  width: 100% !important;
  height: 100% !important;
}

// modal
.spaces-slider-modal .modal-dialog {
  // height: 100vh;
  display: flex;
  align-items: center;
  border-radius: 16px !important;
}

.spaces-slider-modal .modal-content {
  // height: 100%;
  // height: 80vh;
  overflow-y: auto;
  border-radius: 16px !important;
}

.spaces-slider-custom-modal {
  // --bs-modal-width: 100wh;
  // height: 100vh;
  border-radius: 16px !important;
}

.cslider {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.cslider-items {
  transition: transform 0.5s ease;
}

.cslider-item {
  min-width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  overflow: hidden;
}

// // add product checkbox
.checkbox-container {
  position: relative;
  display: inline-block;
}

.checkbox-container input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: -9999px;
}

.checkbox-container label {
  position: relative;
  cursor: pointer;
  width: 40px;
  /* Increase size */
  height: 40px;
  /* Increase size */
  background-color: #fff;
  border: 3px solid #ccc;
  /* Increase border thickness */
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-container input[type="checkbox"]:checked+label {
  background-color: #4caf50;
  border-color: #4caf50;
}

.checkbox-container input[type="checkbox"]:checked+label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  /* Increase checkmark size */
  height: 20px;
  /* Increase checkmark size */
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

// react-select
.css-1nmdiq5-menu {
  z-index: 1000 !important;
}

.icon-hover {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-hover:hover {
  border-radius: 50%;
  background-color: #e8eaed;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select {
  appearance: none;
  /* Hides default arrow */
  -webkit-appearance: none;
  /* For Safari and Chrome */
  -moz-appearance: none;
  /* For Firefox */
  background: none;
  /* Removes any background arrow */
  border: 1px solid #bdc1c6;
  /* Your border styles */
  border-radius: 3px;
  padding-right: 30px;
  /* Space for the custom icon */
}

.custom-select::-ms-expand {
  display: none;
  /* For IE and Edge to remove default dropdown icon */
}

.custom-dropdown-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-close {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  // pointer-events: none;
}

.location-dropdown-container {
  width: 150px !important;
  z-index: 2;
  border: 1px solid #e8eaed;
  background-color: #ffffff;
  border-radius: 5px;
}

.search-dropdown-container {
  // width: 600px !important;
  width: 100% ;
  z-index: 2;
  border: 1px solid #e8eaed;
  background-color: #ffffff;
  border-radius: 5px;
}

.huge-dropdown-container {
  width: 100% !important;
  z-index: 2;
  border-top: 0;
  border-top: 1px solid #e8eaed;
  border-bottom: 1px solid #e8eaed;
  background-color: #ffffff;
  border-radius: 0px;
}

.huge-dropdown-overlay {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.small-img {
  border: 1px solid #f1f3f4;
  border-radius: 3px;
}

.small-img:hover {
  border: 1px solid #bdc1c6;
}

.small-image {
  border: 1px solid #f1f3f4;
  border-radius: 3px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  /* Ensures the image fits without stretching */
}

.small-image:hover {
  border: 1px solid #bdc1c6;
}

.image-container {
  width: 80px;
  height: 80px;
  overflow: hidden;
  /* Ensures the image does not overflow */
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparent-button {
  background: #ffffff !important;
  color: #c53d3d;
}

.transparent-button:hover {
  background: #fdf3f3 !important;
  color: #c53d3d;
}

.hovered-point {
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1.5px solid #ffffff;
  background-color: $primary;
  border-radius: 50%;
  cursor: pointer;
}

.hovered-point:hover {
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1.5px solid #ffffff;
  background-color: #000000;
  border-radius: 50%;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: -30px;
  left: 15px;
  border-radius: 3px;
  padding: 5px 5px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip.visible {
  visibility: visible;
  opacity: 1;
}

.first_section_home_page {
  background-image: url("./assets/home/home_first_section_background.webp");
  background-size: cover;
}

.custom-search-placeholder::placeholder {
  color: #80868b;
  /* Set the placeholder color */
  opacity: 1;
  /* Ensures the color is not affected by browser defaults */
}

.white-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  /* Adjust opacity as needed */
  pointer-events: none;
}

.slider-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  /* Adjust opacity as needed */
  pointer-events: none;
}

.brand-thoughts {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 6.0em; /* Adjust based on line height */
  white-space: normal;
}

.brand-card {
  min-height: 120px; /* Adjust based on design */
}

.brand-divider {
  border-top: 1px solid #ddd;
  margin: 8px 0;
}

.brand-image-wrapper {
  width: 120;  /* Square size */
  height: 120; /* Square size */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 12px; /* Rounded edges */
  background: #f8f9fa; /* Optional background */
}

.brand-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image stays centered */
  border-radius: 12px; /* Matches wrapper's rounded edges */
}