/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*/

.empty-state {
  background-color: #f5f5f5; /* Light grey background */
  min-height: 60vh; /* Adjust height as needed */
  display: flex;
  flex-direction: column; /* Stack icon and text vertically */
  align-items: center;
  justify-content: center;
  border-radius: 8px; /* Optional: adds rounded corners */
  color: #6c757d; /* Muted text color */
  text-align: center;
  width: 100%;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .empty-state {
    /* Centering is already handled by flex styles.
       You can add extra mobile-specific adjustments here if needed. */
    padding: 0 1rem; /* For example, add horizontal padding on small screens */
  }
}

.icon {
  margin-bottom: 0.5rem;
}


/* Add this to your CSS file */
.breadcrumb {
  padding: 10px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.breadcrumb a {
  color: #007bff;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.titletextbtnbox {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  width: calc(100% - 252px);
  padding-left: 24px;
}

.titletextbtnbox.interiotitlebox {
  flex-wrap: wrap;
}



.titleimgtext .titleimg {
  width: 100%;
  max-width: 250px;
  height: 170px;
}

.titletextbtnbox .titletextbtn{
  min-width: 253px;
}

.enqbtn{
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.starbtn {
  min-width: 56px;
  padding: 3px;
}

.reviewtabbtn {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  gap: 15px;
}

.reviewtabbtn .leftpart { 
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

}


.reviewtabbtn button {
  padding: 6px 14px;
  font-size: 14px;
}

.reviewtabbtn .rightpart  {  
  min-width: 102px;
}

.reviewtabbtn .rightpart button {  
    width: 100%;
    display: block !important;
}


/*  */

.react-datepicker-wrapper .react-datepicker__input-container input {
  border: 1px solid #BDC1C6 !important;
  border-radius: 3px;
}

.room-images-row > .col:only-child {
  flex: 0 0 100%;
  max-width: 100%;
}

.hover-text-primary:hover {
  color: var(--bs-primary) ;
}

.card-hover:hover {
  transform: scale(1.03); /* Lifts and zooms */
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15); /* Stronger shadow */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}





.arctectpage .pageTitle {
  max-width: 1236px;
  width: 100%;
  margin: 50px auto 0;
  padding: 72px 10px;
}

.arctectpage .pageTitle h1 {
  font-weight: 500;
  line-height: normal;
  letter-spacing: -3%;
  text-align: center;
}

.arctectpage .pageTitle.maintitleBox h1 {
  max-width: 926px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

.arctectpage .pageTitle.maintitleBox div { 
  max-width: 830px;
  margin:  0 auto;
  font-size: 24px;
  color: #1A1C1F;
}

.qandasection .card {
  padding: 5px 0 0 60px;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #EBEBEB;
  position: relative;
}

.qandasection .card:before {
  content: "";
  position: absolute;
  top: 16px;
  left: 65px;
  width: 1px;
  height: calc(100% - 28px);
  background: #A53030;
}

.qandasection .card .qnumber{
  position: absolute;
  top: 14px;
  left: 20px;
  font-size: 16px;
  font-weight: 600;
}

.qandasection .card-header {
  border-bottom: 0px;
  background-color: transparent;
}

.qandasection .card-body {
  text-align: left;
  max-width: 70%;
}


/* Partner architect */


.architectimg > img {
  width: 100%;
  height: 208px;
  object-fit: cover;
}

.architectmainimg { 
  width: 355px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}


.architectmainimg > img {
  max-height: 100%;
  max-width: 135%;
  object-fit: contain;
}

.step3tabbox{
  display: flex;
  justify-content: space-between;
}

.step3tabboxitem > img{ 
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.qandasection .card-header  {
  text-align: left;
}

.serchessection{
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    width:100%;
    margin: 0 auto;
    justify-content: space-between;
    /* max-width: 1450px;  */
    gap: 30px;
  }

  .serchessection > .serchesbox {
    width: 22%;
  }

  .serchessection > .serchesbox span {
    font-size: 45px;
  }

  .serchessection > .serchesbox p {
    font-size: 21px;
    margin: 20px 0 0;
  }


  .sendenquirypopup {
    overflow-y: auto;
  }


  .sendenquirypopup .modal-dialog-centered {
    width: 90%;
    max-width: 550px !important;
    margin-left: auto;
    margin-right: auto;
  }


.sendbodytitle{
  display: flex;
  justify-content: space-between;
}

.sendbodytitle > .leftpart {
  width: 86px;
  border: 1px solid #AFBACA;
  border-radius: 5px;
  padding: 5px;
}
.sendbodytitle > .leftpart > img {
  width: 100%;
}

.sendbodytitle > .rightpart { 
  width: calc(100% - 90px);
  padding-left: 12px;
}


.sendbodytitle > .rightpart h5 {
  color: #303030;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.sendbodytitle > .rightpart p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 0;
  color: #303030;
}

.sendbodytitle > .rightpart .btngroup{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 9px;
  margin: 5px 0 0;
}

.sendbodytitle > .rightpart .btngroup > span{ 
  display: inline-flex;
  color: #2E3134;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #F1F3F4;
  padding: 5px;
}


.sendenquirypopup form {
  margin: 24px 0 0;
}

.sendenquirypopup form .form-label{
  font-size: 14px;
  font-weight: 500;
  color: #1A1C1F;
}

.sendenquirypopup form .form-control{
  border: 1px solid #BDC1C6;
  border-radius: 5px;
}

.sendenquirypopup form .input-group-text {
  background-color: transparent;
}

.sendenquirypopup form #formPhone.form-control{
  border-left: none;
}

.sendenquirypopup form #formCheckbox{
  border-color: #3C4043;
}

.architectbtn{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 8px;

}

  

/********************************* media css *************************************************/

@media(max-width:1599px) { 
  .titletextbtnbox.interiotitlebox .titletextbtn button {
    font-size: 13px;   
  }

}



@media (min-width: 1200px) {
  .container {
    max-width: 1380px !important;
  }
}

@media(max-width:1250px) { 

  .reviewtabbtn button {
      padding: 3px;
      font-size: 12px;
  }
  .serchessection > .serchesbox p {
    font-size: 19px;
      margin: 10px 0 0;
  }

  .serchessection > .serchesbox span {
    font-size: 40px;
  }

  .titleimgtext .titleimg {
    max-width: 200px;
  }

  .titletextbtnbox {
    width: calc(100% - 200px);
  }

}


@media(max-width:1199px) {  

  .titleimgtext>.container {
    max-width: 100%;
  }

  .interotabssection .interotabspart,
  .interotabssection .reviewpart {
    max-width: 100%;
    padding: 0;
  }

  .titletextbtnbox.interiotitlebox .titletextbtn {
    width: 100%;
    min-width: 100%;
    flex-wrap: wrap;
  }

  .architectmainimg {
    width: 100%;
  } 
 
  .architectimg > img {
    width: auto;
    height: 226px;
    min-width: 100%;
  }


  .serchessection > .serchesbox p {
      font-size: 18px;
  }

  .arctectpage .pageTitle.maintitleBox div {
    font-size: 18px;
  }



}

@media(max-width:991px) {
  
  .titletextbtnbox {
    flex-wrap: wrap;
  }

  .titletextbtnbox .titletextbtn {
    width: 100%;
    margin-top: 10px;
  }

  .architectimg > img {
    height: 155px;
  }

  .serchessection > .serchesbox span {
    font-size: 38px;
  }

  .serchessection > .serchesbox p {
      font-size: 16px;
  }

  .serchessection {
    gap: 15px;
  }
  
  .serchessection > .serchesbox {
    width: calc(100% / 2 - 12px);
    text-align: center;
  }
  
  .qandasection .card-header ,
  .qandasection .card .qnumber{
    font-size: 14px;
  }

  .designerbox { 
    flex-wrap: wrap;
  }


  .arctectpage .pageTitle.maintitleBox div {
    font-size: 16px;
  }



}


@media(max-width:767px) {
  
  .architectbtn{
    position: static;
    margin: 8px 0 0 0;
  }

}

@media(max-width:680px) { 
  .enqbtn {
    margin-top: 5px;
  }
  .qandasection .card {
    padding: 10px 0px 10px 45px;
  }

  .qandasection .card .qnumber {
    left: 8px;
  }

  .qandasection .card:before { 
    left: 48px;
  } 
  
  
}

@media(max-width:630px) {
  .titletextbar{
    flex-wrap: wrap;
  }

  .titleimgtext .titleimg {
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .titletextbtnbox {
    width: 100%;
    padding-left: 0;
  }

  .reviewtabbtn button {
    font-size: 8px;
  }
  .reviewtabbtn .rightpart {
    min-width: 58px;
}

.reviewtabbtn {
  padding: 20px 0;
}

}

@media(max-width:575px) { 
  .arctectpage .pageTitle {
    margin-top: 40px;
    padding: 0 10px 20px;
  }
}
